@font-face {
  font-family: "Poppins-Light";
  src: url('./Poppins-Light.ttf');
}
@font-face {
  font-family: "Poppins-Regular";
  src: url('./Poppins-Regular.ttf');
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url('./Poppins-SemiBold.ttf');
}
@font-face {
  font-family: "Poppins-Bold";
  src: url('./Poppins-Bold.ttf');
}
@font-face {
  font-family: "Poppins-Medium";
  src: url('./Poppins-Medium.ttf');
}
@font-face {
  font-family: "Poppins-BoldItalic";
  src: url('./Poppins-BoldItalic.ttf');
}



@font-face {
  font-family: "Rubik-Regular";
  src: url('./Rubik-Regular.ttf');
}
@font-face {
  font-family: "Rubik-Medium";
  src: url('./Rubik-Medium.ttf');
}
@font-face {
  font-family: "Rubik-SemiBold";
  src: url('./Rubik-SemiBold.ttf');
}
@font-face {
  font-family: "Rubik-Bold";
  src: url('./Rubik-Bold.ttf');
}
@font-face {
  font-family: "Rubik-ExtraBold";
  src: url('./Rubik-ExtraBold.ttf');
}


